// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-minimal-src-pages-404-js": () => import("./../../../../gatsby-theme-minimal/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-404-js" */),
  "component---gatsby-theme-minimal-src-pages-indexnoop-js": () => import("./../../../../gatsby-theme-minimal/src/pages/indexnoop.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-indexnoop-js" */),
  "component---gatsby-theme-minimal-src-templates-article-menu-template-js": () => import("./../../../../gatsby-theme-minimal/src/templates/ArticleMenuTemplate.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-article-menu-template-js" */),
  "component---gatsby-theme-minimal-src-templates-article-template-js": () => import("./../../../../gatsby-theme-minimal/src/templates/ArticleTemplate.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-article-template-js" */),
  "component---src-gatsby-theme-minimal-templates-page-template-js": () => import("./../../../src/gatsby-theme-minimal/templates/PageTemplate.js" /* webpackChunkName: "component---src-gatsby-theme-minimal-templates-page-template-js" */),
  "component---src-pages-cooking-with-us-test-js": () => import("./../../../src/pages/cooking-with-us-test.js" /* webpackChunkName: "component---src-pages-cooking-with-us-test-js" */)
}

